@use '../styles/variables.scss' as *;

.drive-block__ad {
  &__wrapper {
    @apply w-full;
    @apply flex;
    @apply justify-center;
  }
  &__sticky {
    @apply w-full;
    @apply flex;
    @apply justify-center;
    @apply bg-white;
    @apply sticky;
    @apply top-0;

    @apply z-50;
    @apply overflow-visible;
    @apply py-10px;
    @screen md {
      @apply py-0;
      @apply static;
      @apply bg-transparent;
    }
    &--under-header {
      top: $header-height-mobile;
    }
  }

  &__margin-around-ads {
    margin: 2.25rem 0;
    @media (min-width: 600px) {
      margin: 2.75rem 0;
    }
  }

  &__no-margin-around-ads {
    @apply m-0;
  }

  &__hr {
    border: none;
    margin: 0;
  }

  &__container {
    @apply bg-white;
    @apply flex;
    &:global(.gam_textstickydesk_pos1),
    &:global(.gam_textstickymobile_pos1) {
      @apply bg-transparent;
    }
  }

  &__blocks {
    .margin-around-ads {
      margin: 2.25rem 0;

      @media (min-width: 600px) {
        margin: 2.75rem 0;
      }
    }
  }
}

.drive-block {
  &__wrapper {
    @apply mt-3;
    //underline links within list and paragraph
    li,
    p {
      a {
        @apply underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
