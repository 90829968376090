@use '/styles/mixin/lighbox.module.scss' as *;
@use '/styles/mixin/scroll.module.scss' as *;
@use '/styles/mixin/fonts.module.scss' as *;
@use './lightbox-mixins.module.scss' as *;

.drive-lightbox__gallery {
  @apply text-base;
  @apply text-black;
  @apply font-primary;
  @apply font-semibold;
  @apply bg-white/50;
  @apply text-12px;
  @screen xl {
    @apply text-15px;
  }
}

.drive-lightbox {
  --topAdHeight: 0px;

  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
  @apply fixed;
  @apply bg-black;
  @apply text-white;
  @apply z-[100];

  &--invisible {
    @apply invisible;
  }

  &__ad-wrapper {
    @apply w-full;
    @apply bg-gray-100;
    @apply text-black;
    @apply pt-2;
    @apply pb-2;
    @screen lg {
      @apply hidden;
    }
  }

  &__ad {
    @apply w-full;
    @apply flex;
    @apply justify-items-center;

    // top
    &__wrapper {
      @apply hidden;
      @apply justify-end;
      @screen lg {
        @apply flex;
      }
    }
    &__sticky-mobile {
      @apply flex;
      @screen lg {
        @apply hidden;
      }
    }
  }

  &__container-wrapper {
    @apply flex;
    @apply min-w-full;
    @apply overflow-hidden;
    @apply justify-center;
    // set from lightbox
    height: calc(100% - var(--topAdHeight));
    @screen lg {
      @apply h-full;
    }
    &--show-enquiry {
      @apply pb-[75px];
      @screen lg {
        @apply pb-0;
      }
    }
  }

  // inside wrapper
  &__container {
    height: calc(100% - $navHeight);
    @include hide-scroll;
    @apply overflow-hidden;
    @apply relative;
    @apply w-full;
    &--stack-mode {
      /*
      iOS sometimes doesn’t fully respect width: 100% on certain elements,
      especially within flex containers or when dealing with dynamically loaded content
      having min width set is a workaround
      */
      @apply min-w-full;
    }
    &--stack-zoom-mode {
      @include for-mobile-wide-landscape {
        touch-action: manipulation;
        width: calc(100% - 136px * 2); // leave space for nav buttons on the side
        @apply ml-[136px];
        @apply h-auto;
        @apply max-h-full;
      }
    }
  }

  &__container-wrapper > &__container {
    @apply w-auto;
  }

  &__open-btn {
    @apply absolute;
    @apply bottom-0;
    @apply right-0;
    @apply px-[0.563rem];
    @apply mr-4;
    @apply bg-white;
    @apply mb-4;
    @apply min-h-[2.125rem];
    @apply rounded-lg;
    @apply transition-opacity;
    @apply duration-500;
    @apply flex;
    @apply items-center;
    @include font-caption('mobile', 'bold');
    @screen md {
      @include font-button;
    }

    @screen lg {
      @apply min-h-10;
      @apply bottom-4;
      @apply px-[0.75rem];
      @apply mr-6;
      @apply mb-6;
    }

    &:hover {
      @apply text-blue-normal;
    }

    &--ghostlisting {
      @apply mb-[42px];
      @screen lg {
        @apply mb-6;
      }
    }
  }

  &__btn {
    &__wrapper {
      @include font-caption('mobile', 'bold');
      @screen md {
        @apply py-[3.5px];
        @include font-button;
        @apply flex;
      }
    }
    &__icon {
      @apply fill-current;
      @apply mr-1;
    }
  }
}
